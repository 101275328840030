import { render, staticRenderFns } from "./fileLearn.vue?vue&type=template&id=abc4e638&scoped=true"
import script from "./fileLearn.vue?vue&type=script&lang=js"
export * from "./fileLearn.vue?vue&type=script&lang=js"
import style0 from "./fileLearn.vue?vue&type=style&index=0&id=abc4e638&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "abc4e638",
  null
  
)

export default component.exports