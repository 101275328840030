<template>
  <div class="container">
    <div class="searchBox">
      <div class="_SelectHeader">
        <div class="_Select">
          <span style="width: 60px">公司：</span>
          <el-select
            v-model="company"
            placeholder="请选择公司"
            @change="changCompany"
            clearable
            :disabled="AssignToThis"
          >
            <el-option
              v-for="item in companyList"
              :key="item.id"
              :value="item.id"
              :label="item.companyName"
            ></el-option>
          </el-select>
        </div>
        <div class="_Select">
          <span style="width: 60px">部门：</span>
          <el-select
            placeholder="请选择部门"
            class="select"
            v-model="dept"
            clearable
            :disabled="AssignToThis"
            @change="changeDept"
          >
            <el-option
              v-for="item in deptList"
              :key="item.id"
              :value="item.id"
              :label="item.deptmentName"
            ></el-option>
          </el-select>
        </div>
        <div class="_Select">
          <span style="width: 60px">岗位：</span>
          <el-select
            placeholder="请选择岗位"
            class="select"
            v-model="post"
            clearable
            :disabled="AssignToThis"
            @change="changPost"
          >
            <el-option
              v-for="item in postList"
              :key="item.id"
              :value="item.id"
              :label="item.postName"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="_SelectHeader" style="padding-top: 0">
        <div class="_Select" style="width: 690px">
          <span style="width: 60px">文件名：</span>
          <el-select
            placeholder="请选择文件名"
            class="select file-select"
            v-model="checkFileList"
            multiple
            style="width: 100%"
          >
            <el-option
              v-for="item in fliesList"
              :key="item.value"
              :value="item.fileNo"
              :label="item.fileName"
            ></el-option>
          </el-select>
        </div>
        <!-- <el-button icon="el-icon-search" @click="search"></el-button> -->
        <el-checkbox v-model="checked">随机</el-checkbox>
        <el-button type="primary" @click="selectNum">{{
          showBtn ? "重新抽题" : "获取抽题数"
        }}</el-button>
        <div class="assign" @click="$router.push('./assignStudy')">
          <img src="../../assets/images/study.png" alt />
          <span>指派学习</span>
        </div>
        <a
          href="http://10.0.0.127:81/prg/go.aspx"
          style="text-decoration: none; width: 110px"
          target="_Blank"
          class="assign"
        >
          <i
            class="el-icon-s-promotion"
            style="font-size: 25px; padding: 2px"
          ></i>
          <p style="color: #646870">文件制度查询</p>
        </a>
        <div class="assign" @click="toTest">
          <img src="../../assets/images/test.png" alt />
          <span>模拟测试</span>
        </div>
      </div>
    </div>
    <div class="_TableBox learn">
      <el-table
        ref="multipleTable"
        :data="tableData.slice(start, end)"
        stripe
        tooltip-effect="dark"
        style="width: 100%"
        :height="elementHeight"
      >
        <el-table-column
          type="index"
          label="序号"
          width="120"
        ></el-table-column>
        <el-table-column
          prop="interpretation"
          label="应知应会"
        ></el-table-column>
        <!-- <el-table-column
          prop="lean"
          label="是否已学完"
          width="300"
          v-if="!AssignToThis"
        >
          <template slot-scope="scope">
            <img
              @click="goStudy(scope.row)"
              v-if="scope.row.statues == 0"
              src="../../assets/images/no.png"
              alt
            />
            <img
              v-if="scope.row.statues == 1"
              src="../../assets/images/is.png"
              alt
            />
          </template>
        </el-table-column>
        <el-table-column prop="whether" label="是否已学完" width="300" v-else>
          <template slot-scope="scope">
            <img
              @click="goAssStudy(scope.row)"
              v-if="scope.row.statues == 0"
              src="../../assets/images/no.png"
              alt
            />
            <img
              v-if="scope.row.statues == 1"
              src="../../assets/images/is.png"
              alt
            />
          </template>
        </el-table-column> -->
        <el-table-column
          prop="lean"
          label="是否已学习"
          width="200"
          v-if="!AssignToThis"
        >
          <template slot-scope="scope">
            <el-button
              @click="goStudy(scope.row)"
              type="primary"
              v-if="scope.row.statues == 0"
              >未学习</el-button
            >
            <span v-if="scope.row.statues == 1" style="color: #388ff2"
              >已学习</span
            >
          </template>
        </el-table-column>
        <el-table-column prop="lean" label="是否已学习" width="200" v-else>
          <template slot-scope="scope">
            <el-button
              @click="goAssStudy(scope.row)"
              type="primary"
              v-if="scope.row.status == 0"
              >未学习</el-button
            >
            <span v-else style="color: #388ff2">已学习</span>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage1"
        :page-sizes="[10, 20, 50]"
        :page-size="curSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.length"
        class="_Pagination"
      ></el-pagination>
    </div>
    <el-dialog
      title="重新学习"
      :visible.sync="fileDialog"
      width="47"
      top="25vh"
    >
      <div class="file-dialog">
        <p v-if="!showFile">
          您已经完成文件学习！如需重新学习，请点击<el-button
            type="primary"
            @click="showFile = true"
            >重新学习</el-button
          >
        </p>
        <div v-if="showFile">
          <el-select
            v-model="noCheckFileList"
            multiple
            placeholder="请选择不被重新学习的文件"
          >
            <el-option
              v-for="item in fliesAllList"
              :key="item.value"
              :label="item.fileName"
              :value="item.fileNo"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <span slot="footer">
        <el-button type="primary" v-if="showFile" @click="handleReqData"
          >确 定
        </el-button>
        <el-button @click="handleClose"> 取 消</el-button>
      </span>
    </el-dialog>

    <el-dialog title="获取抽题数" :visible.sync="drawDialog" width="682px">
      <div class="dialogBox">
        <div>抽取题目</div>
        <div>
          <div>
            <span>总题目数</span>
            <span>抽取题目数</span>
          </div>
          <div>
            <span>{{ this.count }}</span>
            <span>
              <img
                src="../../assets/images/sub.png"
                alt
                @click="handleDrawnNumber(0)"
              />
              <el-input
                :disabled="checkedTheme ? true : false"
                v-model="drawncount"
                @input="inputInp"
              ></el-input>
              <img
                src="../../assets/images/add.png"
                alt
                @click="handleDrawnNumber(1)"
              />
            </span>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="drawDialog = false">取 消</el-button>
        <el-button type="primary" @click="deawQuestion">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { get, post, put } from "../../api/http";
export default {
  data() {
    return {
      AssignToThis: null, //指派学习
      showBtn: false,
      company: "",
      dept: "",
      post: "",
      checkFileList: "",
      companyList: [],
      deptList: [],
      postList: [],
      fliesList: [],
      checked: false,
      elementHeight: 0,
      checkedTheme: false,
      // 抽题
      count: 0,
      drawncount: 0,
      drawDialog: false,
      // 分页
      start: 0,
      end: 10,
      currentPage1: 1,
      curSize: 10,
      tableData: [],
      // 重新学习
      fileDialog: false,
      showFile: false,
      fliesAllList: [],
      noCheckFileList: [],
    };
  },
  watch: {
    $route(to, v) {
      // console.log(to, v)
      if (v.path == "/assignStudy" && to.path == "/file-learn") {
        this.AssignToThis = true;
        if (this.$route.query.compId == undefined) {
          this.tableData = [];
          return;
        }
        if (this.$route.query.isTheme === 0) {
          this.getAssignStudyRecord(
            this.$route.query.compId,
            this.$route.query.deptId,
            this.$route.query.postId,
            this.$route.query.AssId
          );
        } else {
          this.checkedTheme = true;
          this.getAssignThemeStudyRecord(this.$route.query.AssId);
        }
        this.changCompany(Number(to.query.compId))
        this.changeDept(Number(to.query.deptId))
        this.post = Number(to.query.postId)
      } else {
        this.AssignToThis = false;
      }
    },
  },
  mounted() {
    this.getElementHeight();
    this.companyList = this.$store.state.companyUserList;
    //指派第一次到这里是触发
    if (this.$route.query.compId !== undefined) {
      this.AssignToThis = true;
      this.getAssignStudyRecord(
        this.$route.query.compId,
        this.$route.query.deptId,
        this.$route.query.postId,
        this.$route.query.AssId
      );
      this.changCompany(Number(this.$route.query.compId))
      this.changeDept(Number(this.$route.query.deptId))
      this.post = Number(this.$route.query.postId)
    } else if (this.$route.query.isTheme == 2) {
      this.AssignToThis = true;
      this.checkedTheme = true;
      this.getAssignThemeStudyRecord(this.$route.query.AssId);
    }
  },
  methods: {
    getAssignStudyRecord(e, v, t, m) {
      get(
        "/api/AssignStudyRecord/GetPostCount?CompId=" +
          e +
          "&DeptId=" +
          v +
          "&PostId=" +
          t
      ).then((res) => {
        this.count = res.count;
        this.drawncount = res.count;
        //this.drawDialog = true
        this.AssignDetail(e, v, t, m);
      });
    },
    AssignDetail(e, v, t, m) {
      get("api/AssignStudyRecord/GetByAssignId?AssignId=" + m).then((resp) => {
        if (resp.code == 200) {
          this.tableData = resp.data;
          this.drawDialog = false;
        } else {
          this.$message.error(resp.message);
        }
      });
    },
    getElementHeight() {
      var clientHeight = window.innerHeight;
      this.$nextTick(() => {
        this.elementHeight = clientHeight - (60 + 90 + 80); //70是div底部距离底部的高度
      });
    },

    // 下拉框
    getDeptList() {
      get("/api/Department/GetNoTree?CompId=" + this.company).then((resp) => {
        if (resp.code == 200) {
          this.dept = "";
          this.deptList = resp.data;
          if (this.$route.query.deptId) {
            this.dept = Number(this.$route.query.deptId)
          }
        }
      });
    },
    getPost() {
      get("/api/Post?CompId=" + this.company + "&DeptId=" + this.dept).then(
        (res) => {
          this.postList = res.data;
        }
      );
    },
    getFliesList() {
      get(
        "/api/StudyByPost/GetFileList?CompId=" +
          this.company +
          "&DeptId=" +
          this.dept +
          "&PostId=" +
          this.post
      ).then((res) => {
        this.fliesList = res.data;
      });
    },
    changCompany(e) {
      this.company = e;
      this.dept = "";
      this.post = "";
      this.checkFileList = [];
      if (e) this.getDeptList();
    },
    changeDept(e) {
      this.dept = e;
      this.post = "";
      this.checkFileList = [];
      if (e) this.getPost();
    },
    changPost(e) {
      if (!e) return;
      this.post = e;
      this.getRecord();
      this.checkFileList = [];
      this.getFliesList();
    },
    // 表格数据
    getRecord() {
      get(
        "/api/Studylist/" +
          JSON.parse(sessionStorage.getItem("userInfo")).userNo +
          "?compid=" +
          this.company +
          "&deptid=" +
          this.dept +
          "&postid=" +
          this.post
      ).then((res) => {
        if (res.code == 200) {
          this.tableData = res.data;
          this.showBtn = res.data.length != 0 ? true : false;
        }
      });
    },
    // 搜索按钮
    search() {
      this.getRecord();
    },
    handleSizeChange(e) {
      this.curSize = e;
      this.start = (this.currentPage1 - 1) * this.curSize;
      this.end = this.start + this.curSize;
    },
    handleCurrentChange(e) {
      this.start = (e - 1) * this.curSize;
      this.end = this.start + this.curSize;
    },
    // 获取抽题数
    selectNum() {
      if (!this.post && !this.checkedTheme) {
        this.$message({
          type: "warning",
          message: "未选择公司/部门/岗位",
        });
        return;
      }
      this.getCount();
    },
    getCount() {
      if (!this.checkedTheme) {
        var param =
          "?CompId=" +
          this.company +
          "&DeptId=" +
          this.dept +
          "&PostId=" +
          this.post;
        var list = [];
        var item = {};
        this.fliesList.map((i) => {
          this.checkFileList.map((e) => {
            if (i.fileNo == e) {
              item = {
                fileNo: i.fileNo,
                fileName: i.fileName,
              };
              list.length == 0
                ? list.push(JSON.stringify(item))
                : (list = list + "," + JSON.stringify(item));
            }
          });
        });
        list = "[" + list + "]";
        var ReqHeader =
          this.checkFileList.length == 0
            ? get("/api/StudyByPost/GetPostCount" + param)
            : post("/api/StudyByPost/GetByFilesCount" + param, list);
      } else {
        ReqHeader = get("/api/ThemeStudyRecord/GetThemeCount");
      }
      ReqHeader.then((resp) => {
        if (resp.code == 200) {
          this.count = resp.count;
          this.drawncount = resp.count;
          this.drawDialog = true;
        } else {
          this.$message.error(resp.message);
        }
      });
    },
    deawQuestion() {
      var url = "";
      var list = [];
      var item = {};
      var reqHeader = function () {};
      var param =
        "?CompId=" +
        this.company +
        "&DeptId=" +
        this.dept +
        "&PostId=" +
        this.post +
        "&QuesNo=" +
        this.drawncount;
      if (this.checkedTheme) {
        //按专题抽题
        url = "/api/ThemeStudyRecord/GetQuesByRandom?QuesNo=" + this.drawncount;
        reqHeader = post(url);
      } else if (this.checkFileList.length == 0) {
        if (this.AssignToThis) {
          url = "/api/AssignStudyRecord/GetQuesByRandom";
          reqHeader = post(
            url + param + "&AssignId=" + this.$route.query.AssId
          );
        } else {
          url = "/api/StudyByPost/GetQuesByRandom";
          reqHeader = post(url + param);
        }
        //按岗位抽题
      } else {
        this.fliesList.map((i) => {
          //按文件抽题
          this.checkFileList.map((e) => {
            if (i.fileNo == e) {
              item = {
                fileNo: i.fileNo,
                fileName: i.fileName,
              };
              list.length == 0
                ? list.push(JSON.stringify(item))
                : (list = list + "," + JSON.stringify(item));
            }
          });
        });
        list = "[" + list + "]";
        url = "/api/StudyByPost/PostByFiles";
        reqHeader = post(url + param, list);
      }
      reqHeader
        .then((resp) => {
          console.log(resp);
          if (resp.code == "200") {
            if (this.AssignToThis) {
              this.$message({
                type: "success",
                message: resp.message,
              });
              this.getAssignStudyRecord(
                this.$route.query.compId,
                this.$route.query.deptId,
                this.$route.query.postId,
                this.$route.query.AssId
              );
              this.drawDialog = false;
              return;
            }
            if (
              resp.message == "您已经完成文件学习！如需重新学习，请点击重新学习"
            ) {
              this.fileDialog = true;
              this.fliesAllList = this.fliesList;
              return;
            }
            resp.data.map((item) => {
              item.lean = "未学习";
            });

            this.tableData = resp.data;
            this.drawDialog = false;
          } else {
            this.$message.error(resp.message);
          }
        })
        .catch(() => {
          this.$message.error("获取失败");
        });
    },
    handleDrawnNumber(i) {
      if (this.count == 0) {
        this.$message({
          type: "warning",
          message: "暂无抽题数",
        });
        return;
      }
      if (
        (this.count == 0 && i == 0) ||
        (this.drawncount == this.count && i == 1)
      ) {
        this.$message({
          type: "warning",
          message: "已超出范围",
        });
        return;
      }
      this.drawncount = i == 1 ? this.drawncount + 1 : this.drawncount - 1;
    },
    inputInp() {
      if (this.drawncount > this.count) this.drawncount = 0;
      if (this.drawncount < 0) this.drawncount = 0;
    },
    goAssStudy(row) {
      let url = "";
      this.$route.query.isTheme === 0
        ? (url = "/api/AssignStudyRecord/")
        : (url = "/api/AssignThemeStudyRecord/");
      put(url + row.id).then((res) => {
        if (res.code == "200") {
          row.status = 1;
        } else {
          this.$message({
            type: "warning",
            message: "失败",
          });
        }
      });
    },
    goStudy(row) {
      put(
        (!this.checkedTheme ? "/api/StudyByPost/" : "/api/ThemeStudyRecord/") +
          Number(row.id)
      )
        .then((resp) => {
          if (resp.code == 200) {
            row.statues = 1;
          } else {
            this.message.error(resp.message);
          }
        })
        .catch(() => {
          this.message.error("系统异常，请稍后再试");
        });
    },
    // 重新学习,选中的文件不加到参数里
    handleReqData() {
      var reqHeader = function () {};
      if (this.checkedTheme) {
        reqHeader = post("/api/ThemeStudyRecord/PostReStudy");
      } else {
        var list = [];
        var item = {};
        if (this.noCheckFileList.length != 0) {
          this.fliesAllList.map((i) => {
            this.noCheckFileList.map((noC) => {
              if (item.fileNo != noC.fileNo) {
                item = {
                  fileNo: i.fileNo,
                  fileName: i.fileName,
                };
                list.length == 0
                  ? list.push(JSON.stringify(item))
                  : (list = list + "," + JSON.stringify(item));
              }
            });
          });
        }
        reqHeader = post("/api/StudyByPost/PostReStadyFiles", list);
      }
      reqHeader
        .then((resp) => {
          if (resp.code == 200) {
            this.$message({
              type: "success",
              message: "开始重新学习",
            });
            this.fileDialog = false;
          } else {
            this.$message.error(resp.message);
          }
        })
        .catch(() => {
          this.$message.error("重新学习失败");
        });
    },
    handleClose() {
      this.fileDialog = false;
    },
    getAssignThemeStudyRecord(id) {
      get("/api/AssignThemeStudyRecord/GetByAssignId?AssignId=" + id).then(
        (res) => {
          if (res.data.length == 0) {
            this.reGet(id);
          } else {
            this.tableData = res.data;
          }
        }
      );
    },
    reGet(id) {
      post("/api/AssignThemeStudyRecord/PostRandom?AssignId=" + id).then(() => {
        this.getAssignThemeStudyRecord(id);
      });
    },
    toTest() {
      if (this.company && !this.dept && !this.checkedTheme) {
        this.$message({
          type: "warning",
          message: "请选择部门",
        });
        return;
      }
      if (this.checkedTheme) {
        this.$router.push("./test?queryIndex=3");
      } else if (this.company && this.dept) {
        this.$router.push(
          "./test?companyId=" +
            this.company +
            "&deptId=" +
            this.dept +
            "&postId=" +
            this.post +
            "&queryIndex=1"
        );
      } else {
        this.$router.push("./test?queryIndex=2");
      }
    },
  },
};
</script>

 <style lang="less" scoped>
.container {
  width: 100%;
  height: 100%;
  .searchBox {
    ._SelectHeader {
      height: 30px;
      .el-checkbox {
        margin-left: 14px;
        ::v-deep .el-checkbox__label {
          padding-left: 5px;
        }
      }
      .el-button {
        margin-right: 4px;
      }
      .assign {
        margin-left: 8px;
        width: 70px;
        height: 70px;
        background: #f2f8ff;
        border: 1px solid #cdd2dc;
        border-radius: 8px;
        display: flex;
        align-self: flex-end;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        img {
          width: 25px;
          height: 25px;
        }
        span {
          margin-top: 4px;
          font-size: 12px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #646870;
        }
      }
    }
  }
  /deep/ .learn tbody tr > td:nth-of-type(2) {
    text-align: start !important;
  }
  .file-dialog {
    text-align: center;
    > p {
      font-size: 14px;
      margin: 10px 0;
    }
    & .el-button {
      margin: 0 5px;
    }
    & .el-select {
      width: 600px !important;
    }
  }
  .dialogBox {
    display: flex;
    & > div:nth-child(1) {
      width: 60px;
      height: 14px;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #000000;
      margin-right: 16px;
      margin-top: 10px;
    }
    & > div:nth-child(2) {
      flex: 1;
      & > div:nth-child(1) {
        height: 40px;
        background: #ffffff;
        box-shadow: 0px 2px 4px 0px rgba(64, 65, 73, 0.18);
        display: flex;
        align-items: center;
        span {
          flex: 1;
          text-align: center;
        }
      }
      & > div:nth-child(2) {
        height: 40px;
        background: #f8f9fd;
        box-shadow: 0px 2px 4px 0px rgba(64, 65, 73, 0.18);
        display: flex;
        align-items: center;
        justify-content: space-around;
        span {
          flex: 1;
          text-align: center;
          .el-input {
            width: 84px;
            margin: 0 4px;
            ::v-deep .el-input__inner {
              width: 84px !important;
              height: 34px !important;
              border-radius: 0;
              text-align: center;
            }
          }
          img {
            vertical-align: middle;
          }
        }
      }
    }
  }
  /deep/ .file-select .el-select__tags {
    flex-wrap: nowrap !important;
    overflow: hidden;
  }
}
</style>